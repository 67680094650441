// extracted by mini-css-extract-plugin
export var active = "steps-module--active--3S4B9";
export var first = "steps-module--first--StCOJ";
export var flexContainer = "steps-module--flexContainer--1UJXJ";
export var graphicIconHolder = "steps-module--graphicIconHolder--35kn6";
export var headerBox = "steps-module--headerBox--1CbEc";
export var iconHolder = "steps-module--iconHolder--1Y_w9";
export var last = "steps-module--last--1s8YU";
export var pointer = "steps-module--pointer--2blp6";
export var sectionSteps = "steps-module--sectionSteps--3x0Dy";
export var singleStep = "steps-module--singleStep--17xQn";
export var textHolder = "steps-module--textHolder--1-zrM";
import React, { forwardRef, useState } from "react";
import classNames from "classnames";
import { Flex } from "../Flex/Flex";
import { Section } from "../Section/Section";
import { Container } from "../Container/Container";

import { useStateContext, useStateDispach } from "../../state/ContextProvider";

import graphicIcon from "../../images/branding/graphic-cgr-icon.svg";

import * as style from "./textandimage.module.css";
import { Link } from "gatsby";

export const TextAndImage = forwardRef<HTMLDivElement, any>(
	(
		{
			image,
			imagePosition,
			className,
			bgImage,
			children,
			signatureImg,
			html,
			id,
			lang,
			defLang,
			reverse
		},
		ref
	) => {
		const [expanded, setExpanded] = useState(false);

		console.log(reverse)

		// Generate style for
		const classesContainer = classNames({
			[style.imgRight]: imagePosition === "right",
			[style.imgLeft]: imagePosition !== "right",
		});

		// Generate styling for section
		const classesSection = classNames(style.imageAndTextSection, {
			[className]: !!className,
			[style.noBottomPadding]: imagePosition !== "right",
			[style.yesBottomPadding]: imagePosition === "right",
		});

		const expandedClassName = classNames({
			[style.expanded]: expanded,
			[style.hidden]: !expanded,
		});

		const classesTextBox = classNames(style.half, style.textHolder);
		const classesImageBox = classNames(style.half, style.imageHolder);

		const markup = () => {
			return { __html: html };
		};

		return (
			<div ref={ref} id={id}>
				<Section className={classesSection}>
					{bgImage && (
						<div className={style.bgCanvas}>
							<img src={bgImage} />
						</div>
					)}
					<Container>
						<Flex
							options={{ alignV: true, alignH: true, reverse: reverse }}
							className={classesContainer}
						>
							<div className={classesImageBox}>
								<img src={image} alt="" />
							</div>

							<div className={classesTextBox}>
								<div className={style.iconHolder}>
									<img src={graphicIcon} alt="" />
								</div>
								{children}

								<div
									className={expandedClassName}
									dangerouslySetInnerHTML={markup()}
								/>

								{!expanded && (
									<button
										className={style.ctaBtn}
										onClick={e => setExpanded(!expanded)}
									>
										{lang === "es"
											? "Lee mas"
											: "Read more"}
									</button>
								)}

								{signatureImg && (
									<>
										<div className={style.signatureHolder}>
											<img
												src={signatureImg}
												alt="Gerard Ramirez Signature"
											/>
										</div>
										{expanded && (
											<div>
												<Link
													className="ctaButton linkCta"
													to={
														lang === defLang
															? "/#meet-the-team"
															: `/${lang}/#meet-the-team`
													}
												>
													{lang === "es"
														? "CONOCE A NUESTRO EQUIPO"
														: "OUR TEAM"}
												</Link>
											</div>
										)}
									</>
								)}
							</div>
						</Flex>
					</Container>
				</Section>
			</div>
		);
	}
);

import React, { forwardRef } from "react";
import { Section } from "../Section/Section";
import { Flex } from "../Flex/Flex";
import { Container } from "../Container/Container";
import { SingleService } from "./SingleService";

import * as style from "./ourservices.module.css";

import icon from "../../images/branding/pattern-icon.svg";
import bgImage from "../../images/backgroounds/bghouser.png";

export const OurServices = forwardRef<HTMLDivElement, any>(
	({ services, lang, title, defLang }, ref) => {
		const serviceKeys = Object.keys(services);

		const allServiceData = serviceKeys.map(key => {
			return {
				id: services[key].id,
				title: services[key][lang].title,
				description: services[key][lang].description,
				icon: services[key].icon,
			};
		});

		const servicesElements = allServiceData.map((service, index, arry) => {
			const orientation = index % 2;
			const isLast = arry.length - 1 === index;
			const isFirst = index === 0;

			return (
				<SingleService
					key={service.id}
					icon={service.icon}
					orientation={orientation}
					isLast={isLast}
					isFirst={isFirst}
					startIcon={icon}
					lang={lang}
					defLang={defLang}
				>
					<h3>{service.title}</h3>
					<p>{service.description} </p>
				</SingleService>
			);
		});

		return (
			<div ref={ref}>
				<Section className={style.servicesSection}>
					<Container id="services" className={style.container}>
						<div className={style.heading}>
							<h2>{title[lang]}</h2>
						</div>

						<div className={style.services}>{servicesElements}</div>
					</Container>
					<div className={style.bgImage}>
						<img src={bgImage} alt="" />
					</div>
				</Section>
			</div>
		);
	}
);

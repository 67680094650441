import classNames from "classnames";
import React from "react";

import * as styles from "./popup.module.css";

export function Popup({ children, show, ...rest }: any) {
	const classes = classNames(styles.popup, {
		[styles.active]: show,
	});
	return (
		<div className={classes} {...rest}>
			{children}
		</div>
	);
}

import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Autoplay } from "swiper";
import { Section } from "../Section/Section";
import { Container } from "../Container/Container";

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import * as style from "./ourwork.module.css";
import { Link } from "gatsby";

interface IBGalleryModule {
	type: string;
	gallery: any;
	uuid: string;
	locales:  ILocale [];
}

interface ILocale {
	iso:string;
	value:string;
}

interface IBOurWork {
	title: IBLangSep;
	description: IBLangSep;
	lang: string;
	selectedModules: IBGalleryModule[];
}

interface IBLangSep {
	en: string;
	es: string;
}

export function OurWork({
	title,
	description,
	selectedModules,
	lang,
}: IBOurWork) {
	const mapModules = selectedModules.map(
		(module: IBGalleryModule, i: number) => {
			return (
				<SwiperSlide key={i}>
					<Link className={style.ctaSingleItem} to={filterLocaleSlug(lang,module.locales)} >
						<GalleryGrid gallery={module.gallery} />
					</Link>
				</SwiperSlide>
			);
		}
	);

	return (
		<Section className={style.section}>
			<Container>
				<div className={style.textPart}>
					<h2>{title[lang as keyof IBLangSep]}</h2>
					<p>{description[lang as keyof IBLangSep]}</p>
				</div>

				<Swiper
					modules={[EffectFade, Navigation, Autoplay]}
					navigation
					spaceBetween={50}
					slidesPerView={1}
					effect="fade"
					autoplay={{
						delay: 2500,
					}}
				>
					{mapModules}
				</Swiper>
			</Container>
		</Section>
	);
}

export function GalleryGrid({ gallery }: any) {
	const { galleryImages } = gallery;

	const filterFetured = galleryImages.filter((el: any, i: any) => {
		return el.position === 0;
	});

	const filterRestImages = galleryImages.filter((el: any, i: any) => {
		return el.position !== 0 && i <= 4;
	});

	// console.log("My gallery ", filterImages);

	const mapImages = filterRestImages.map((img: any, i: number) => {
		return (
			<GatsbyImage
				key={i}
				image={getImage(img.galleryImage)}
				alt="test"
				className={style.image}
			/>
		);
	});

	return (
		<div className={style.container}>
			<div className={style.featured}>
				<GatsbyImage
					image={getImage(filterFetured[0].galleryImage)}
					alt="test"
				/>
			</div>

			<div className={style.imagesRest}>{mapImages}</div>
		</div>
	);
}

function filterLocaleSlug(lang:string,locales: ILocale[]):string{
	return locales.find(locale => locale.iso === lang).value;
}

import React from "react";
import { Container } from "../Container/Container";
import { Flex } from "../Flex/Flex";
import { Section } from "../Section/Section";
import { SingleStep } from "./SingleStep";

import * as style from "./steps.module.css";

import graphicIcon from "../../images/branding/graphic-cgr-icon.svg";

export function Steps({ steps, lang, title }: any) {
	const renderSteps = steps.map(
		(step: any, index: number, arry: object[]) => {
			const isLast = arry.length - 1 === index;
			const isFirst = index === 0;

			return (
				<SingleStep
					text={step.text[lang]}
					icon={step.icon}
					step={step.id}
					key={step.id}
					isLast={isLast}
					isFirst={isFirst}
				/>
			);
		}
	);

	return (
		<Section className={style.sectionSteps}>
			<Container>
				<div className={style.graphicIconHolder}>
					<img src={graphicIcon} alt="" />
				</div>

				<div className={style.headerBox}>
					<h2>{title[lang]}</h2>
				</div>

				<Flex options={{ alignV: true, alignH: false }}>
					{renderSteps}
				</Flex>
			</Container>
		</Section>
	);
}

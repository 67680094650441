// extracted by mini-css-extract-plugin
export var bgCanvas = "textandimage-module--bgCanvas--oZH7b";
export var ctaBtn = "textandimage-module--ctaBtn--p8ATE";
export var expanded = "textandimage-module--expanded--3GNHG";
export var half = "textandimage-module--half--IG_4o";
export var hidden = "textandimage-module--hidden--377tC";
export var iconHolder = "textandimage-module--iconHolder--3Xshf";
export var imageAndTextSection = "textandimage-module--imageAndTextSection--35H5A";
export var imageHolder = "textandimage-module--imageHolder--1QoUT";
export var imgLeft = "textandimage-module--imgLeft--3AOPH";
export var imgRight = "textandimage-module--imgRight--1216e";
export var noBottomPadding = "textandimage-module--noBottomPadding--2XNog";
export var signatureHolder = "textandimage-module--signatureHolder--14XkL";
export var textHolder = "textandimage-module--textHolder--3JzP1";
export var yesBottomPadding = "textandimage-module--yesBottomPadding--SCh4C";
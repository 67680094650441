import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Autoplay } from "swiper";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import image1 from "../../images/heroSlider/apartment-with-kichen.jpg";
import image2 from "../../images/heroSlider/bathroom-retro-style.jpg";
import image3 from "../../images/heroSlider/wooden-stairs-and-fence.jpg";

import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import * as style from "./heroslider.module.css";

import graphicIcon from "../../images/branding/graphic-cgr-icon.svg";
import { Link } from "gatsby";

const images = [image1, image2, image3];

export function HeroSlider({ title, lang, defLang }: any) {
	const mapSlides = images.map((image, i) => {
		return (
			<SwiperSlide key={"slide-" + i}>
				<section className={style.section}>
					<div className={style.imageHolder}>
						<img src={image} alt="" />
					</div>
				</section>
			</SwiperSlide>
		);
	});

	return (
		<div className={style.swiperHolder}>
			<Swiper
				className="hero-slider"
				modules={[EffectFade, Autoplay]}
				spaceBetween={50}
				slidesPerView={1}
				effect="fade"
				autoplay={{
					delay: 2500,
				}}
			>
				{mapSlides}
			</Swiper>

			<div className={style.overlay}>
				<div>
					<div className={style.graphic}>
						<img src={graphicIcon} alt="" />
					</div>
					<h1>{title[lang]}</h1>

					<div>
						<Link
							to={`${
								lang === defLang
									? "/#contactanos"
									: `/${lang}/#contactanos`
							}`}
							className="ctaButton"
						>
							{lang === "es"
								? "PIDE PRESUPUESTO"
								: "REQUEST A QUOTE"}
						</Link>

						<Link
							className="ctaButton inverted"
							to={
								lang === defLang
									? "/#services"
									: `/${lang}/#services`
							}
						>
							{lang === "es" ? "SERVICIOS" : "OUR SERVICES"}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

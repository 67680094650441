// extracted by mini-css-extract-plugin
export var backgroundReplacer = "ourwork-module--backgroundReplacer--30-eT";
export var container = "ourwork-module--container--2JRwx";
export var content = "ourwork-module--content--1fhSP";
export var ctaSingleItem = "ourwork-module--ctaSingleItem--3tkru";
export var featured = "ourwork-module--featured--13oPn";
export var image = "ourwork-module--image--3JGMZ";
export var imagesRest = "ourwork-module--imagesRest--1e0di";
export var ourWorkListItem = "ourwork-module--ourWorkListItem--Lg74v";
export var projectImg = "ourwork-module--projectImg--2YCoB";
export var section = "ourwork-module--section--93_5z";
export var textPart = "ourwork-module--textPart--suDha";
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { Link } from "gatsby";

import * as style from "./ourservices.module.css";

import { useIntersection } from "../../hook/useIntersection";

export function SingleService({
	children,
	icon,
	orientation,
	isLast,
	isFirst,
	startIcon,
	lang,
	defLang,
}: any) {
	const singeRef = useRef(null);
	const inViewport = useIntersection(singeRef, "-100px");

	const setOrientation = classNames(style.service, {
		[style.right]: orientation === 0,
		[style.left]: orientation === 1,
		[style.last]: isLast,
		[style.first]: isFirst,
		[style.animateIn]: inViewport,
	});

	const setIcon = classNames(style.icon, {
		[style.iconRight]: orientation === 0,
		[style.iconLeft]: orientation === 1,
	});

	const innerDivStyle = classNames(style.inner, {
		[style.innerLast]: isLast,
	});

	return (
		<div ref={singeRef} className={setOrientation}>
			<div className={innerDivStyle}>
				{isFirst && (
					<div className={style.startIcon}>
						<img src={startIcon} alt="" />
					</div>
				)}

				{isLast && (
					<Link
						to={`${
							lang === defLang
								? "/#contactanos"
								: `/${lang}/#contactanos`
						}`}
						className={style.buttonLast}
					>
						{`${lang === "es" ? "Contactanos" : "Contact us"}`}
					</Link>
				)}

				<div className={setIcon}>
					<img src={icon} alt="Icon service" />
				</div>
				<div className={style.textHolder}>{children}</div>
			</div>
		</div>
	);
}

import React, { useRef } from "react";
import classNames from "classnames";
import * as style from "./steps.module.css";
import { useIntersection } from "../../hook/useIntersection";

interface IBSingleStep {
	text: string;
	icon: any;
	alt?: string;
	step: string | number;
	isLast?: boolean;
	isFirst?: boolean;
}

export function SingleStep({
	text,
	icon,
	alt,
	step,
	isLast,
	isFirst,
}: IBSingleStep) {
	const singeRef = useRef(null);
	const inViewport = useIntersection(singeRef, "-150px");

	const singleStepClasses = classNames(style.singleStep, {
		[style.first]: isFirst,
		[style.last]: isLast,
		[style.active]: inViewport,
	});

	return (
		<div ref={singeRef} className={singleStepClasses}>
			

			<div>
				<div className={style.iconHolder}>
					<img src={icon} alt={alt && alt} />
				</div>
				<div className={style.textHolder}>
					<p>{text}</p>
				</div>
			</div>
		</div>
	);
}

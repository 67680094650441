import { Link } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { Container } from "../Container/Container";
import { Flex } from "../Flex/Flex";
import { Popup } from "../Popup/Popup";
import { Section } from "../Section/Section";
import { useForm } from "react-hook-form";
import axios from "axios";

import * as style from "./testimonial.module.css";

const localFormFields: any = {
	name: {
		label: {
			es: "Nombre",
			en: "Full Name",
		},
		errors: {
			required: {
				es: "Este campo es obligatorio",
				en: "This field is required",
			},
		},
	},

	message: {
		label: {
			es: "Comentario",
			en: "Your review",
		},
		errors: {
			required: {
				es: "Este campo es obligatorio",
				en: "This field is required",
			},
		},
	},
	submit: {
		es: "Enviar",
		en: "Send",
	},
};

const localSuccessMessage = {
	title: {
		es: "Hvala što ste nas kontaktirali!",
		en: "Thank you for contacting us!",
	},
	subtitle_1: {
		es: "Procesuiramo vaš zahtev.",
		en: "We are processing your request.",
	},
	subtitle_2: {
		es: "Naš tim će vas uskoro kontaktirati",
		en: "You can expect to hear from our team shortly.",
	},
};

interface IBSectionModule {
	title?: IBLanguageSeparator;
	description?: IBLanguageSeparator;
	selectedModules?: IBModule[];
	lang?: string;
	allTestimonials?: any;
	defLang?: string;
}

interface IBLanguageSeparator {
	en: string;
	es: string;
}

interface IBModule {
	uuid: string;
	locales?: any;
}

interface IBTestimonalNode {
	id: string;
	locales: IBLocaleData[];
}

interface IBLocaleData {
	iso?: string;
	title?: string;
	description?: string;
	content?: string;
}

function TestimonialsList({
	title,
	description,
	selectedModules,
	lang,
	allTestimonials,
	defLang,
}: IBSectionModule) {
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const [serverError, setServerError] = useState("");
	const [formSubmited, setFormSubmited] = useState(false);
	const [showPopup, setShowPopoup] = useState(false);

	// const validateEmail = {
	// 	value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	// 	message: "Please enter a valid email",
	// };

	const handleFormData = async (data: any) => {
		const fields = [];
		for (let type in data) {
			fields.push({
				value: data[type],
				type: type.toUpperCase(),
			});
		}
		const dataToSend = {
			identifier: process.env.WEBFRONT_ID,
			iso: "en",
			fields: fields,
		};
		try {
			const response = await axios.post(
				process.env.FORM_CONTACT_API_URL || "",
				dataToSend,
				{
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
						mode: "cors",
						cache: "no-cache",
						redirect: "follow",
						referrer: "no-referrer",
					},
				}
			);
			setFormSubmited(true);
			setTimeout(() => {
				reset({
					name: "",
					subject: "",
					text: "",
				});
			}, 6000);
		} catch (error: any) {
			if (error.response) {
				setServerError(error.response.data.message);
			} else if (error.request) {
				// console.log(error.request);
			} else {
				// console.log('Error', error.message);
			}
		}
	};

	const mapTestimonials = allTestimonials.map(
		(testimonial: IBTestimonalNode, i: number) => {
			const mapDataObject = testimonial.locales.find(
				(locale: IBLocaleData) => locale.iso === lang
			);

			const titleData: string = mapDataObject.title;
			const contentData: string = mapDataObject.content;

			const markup = () => {
				return { __html: contentData };
			};

			return (
				<article
					key={testimonial.id}
					className={style.singleTestimonial}
				>
					<div dangerouslySetInnerHTML={markup()} />
					<p className={style.name}> {titleData} </p>
				</article>
			);
		}
	);

	return (
		<Section className={style.section}>
			<Container>
				<div className={style.titleArea}>
					<div>
						<h2>{title && title[lang as keyof IBLanguageSeparator]}</h2>
						<p>
							{description &&
								description[lang as keyof IBLanguageSeparator]}{" "}
						</p>
					</div>
					<button
						className={style.btnFix+' ctaButton'}
						onClick={e => setShowPopoup(true)}
					>
						{lang === "es"
							? "Escribe tu reseña"
							: "Submit a testimonial"}
					</button>
				</div>
			</Container>

			<Popup show={showPopup}>
				<button
					className="close"
					onClick={() => setShowPopoup(false)}
				></button>

				<p style={{ textAlign: "center" }}>
					{lang === "es"
						? "Cuéntanos sobre tu experiencia con Grans Reformes"
						: "Tell us about your experience with Grans Reformes"}
				</p>

				{!formSubmited && (
					<form onSubmit={handleSubmit(handleFormData)} method="POST">
						<div className={style.inputField}>
							<input
								type="text"
								{...register("name", {
									required: true,
								})}
								placeholder={localFormFields.name.label[lang]}
							/>
							{errors.name && (
								<span
									style={{
										color: "red",
										fontSize: "13px",
									}}
								>
									{localFormFields.name.errors.required[lang]}
								</span>
							)}
						</div>
						{/* <div className={style.inputField}>
							<input
								{...register("email", {
									required: true,
									pattern: validateEmail,
								})}
								type="email"
								placeholder={
									localFormFields.email.label[lang as any]
								}
							/>
							{errors.email?.type === "required" && (
								<span
									style={{
										color: "red",
										fontSize: "13px",
									}}
								>
									{
										localFormFields.email.errors.required[
											lang as any
										]
									}
								</span>
							)}
							{errors.email?.type === "pattern" && (
								<span
									style={{
										color: "red",
										fontSize: "13px",
									}}
								>
									{localFormFields.email.errors.pattern[lang]}
								</span>
							)}
						</div> */}
						<div className={style.inputField}>
							<textarea
								{...register("text", {
									required: true,
								})}
								cols={30}
								rows={10}
								placeholder={
									localFormFields.message.label[lang]
								}
							/>
							{errors.message && (
								<span
									style={{
										color: "red",
										fontSize: "13px",
									}}
								>
									{
										localFormFields.message.errors.required[
										lang
										]
									}
								</span>
							)}
						</div>
						<button className={style.ctaBtn} type="submit">
							{localFormFields.submit[lang]}
						</button>
					</form>
				)}

				{formSubmited && (
					<h2 style={{ textAlign: "center" }}>
						{" "}
						{lang === "es" ? "!Gracias!" : "Thank you!"}{" "}
					</h2>
				)}
			</Popup>

			<Container>
				<Flex options={{ alignH: false, alignV: false }}>
					{mapTestimonials[0]}
					{mapTestimonials[1]}

					<div className={` ${style.lastCta}`}>
						<Link
							className={style.ctaTestimonios}
							to={`${lang === defLang
									? "/testimonials/index/"
									: `/${lang}/testimonios/index/`
								}`}
						>
							{`${lang === "es"
									? "Todos los Testimonios"
									: "All Testimonials"
								}`}
						</Link>
					</div>
				</Flex>
			</Container>
		</Section>
	);
}

export default TestimonialsList;

import React, { useRef } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout/Layout";
import { HeroSlider } from "../components/HeroSlider/HeroSlider";
import { TextAndImage } from "../components/TextAndImage/TextAndImage";
import { OurServices } from "../components/OurServices/OurServices";
// Resources
import ramirez from "../images/branding/Gerard-Ramirez-bw.png";
import image2 from "../images/hands-house.svg";
import imgAndTextBg from "../images/backgroounds/bg-image.png";
import { Section } from "../components/Section/Section";

import imporovementIcon from "../images/icons/house-icon-improvement.svg";
import comprehensiveIcon from "../images/icons/comprehensive-icon.svg";
import officeIcon from "../images/icons/office-computer.svg";
import collaborationstIcon from "../images/icons/collaborations-paper.svg";

// Steps
import evaluation from "../images/icons/evaluation.svg";
import fastTime from "../images/icons/fast-time.svg";
import wrench from "../images/icons/wrench.svg";
import accounts from "../images/icons/accounts.svg";
import { Steps } from "../components/Steps/Steps";
import { BeforeAndAfter } from "../components/BeforeAndAfter/BeforeAndAfter";
import { OurWork } from "../components/OurWork/OurWork";
import TestimonialsList from "../components/Testimonials/TestimonialsList";

import signatureImg from "../images/branding/signature.svg";
import { Container } from "../components/Container/Container";
import { Banner } from "../components/Banner/Banner";
import { getImage,GatsbyImage } from "gatsby-plugin-image";

// This is temporary until i get all data from server
const servicess: object = {
	improvement: {
		id: 1,
		icon: imporovementIcon,
		en: {
			title: "Improvement reforms",
			description:
				"Each step reveals a new horizon, and the smallest change in your home can translate into an unexpected improvement. We are delighted to carry out the updates that our clients envision.",
		},
		es: {
			title: "Reformas de mejora",
			description:
				"Cada paso conduce a un nuevo punto, así el más pequeño cambio en tu hogar puede traducirse en una mejora inesperada. Estaremos encantados de llevar a cabo las actualizaciones que nuestros clientes imaginan.",
		},
	},
	comprehensive: {
		id: 2,
		icon: comprehensiveIcon,
		en: {
			title: "Comprehensive reforms",
			description:
				"Even the ancient houses can be converted into up-to-date spaces that meet the safety and environmental standards of modern buildings. With such projects, we get the same freedom as with the construction of a new property.",
		},
		es: {
			title: "Reformas integrales",
			description:
				"Incluso los apartamentos más desactualizados pueden convertirse en espacios modernos que alcanzan los estándares de seguridad y medio ambiente de los edificios más modernos. De esta forma conseguimos la misma libertad que con la construcción de un nuevo inmueble.",
		},
	},
	office: {
		id: 3,
		icon: officeIcon,
		en: {
			title: "Office reforms",
			description: `Renewing the workspace results in improving employee motivation and productivity, as well as changing the corporate image.
				With outstanding collaborations with Amazon and Direct Diagnostic, we feel prepared to carry out any project that comes our way.`,
		},
		es: {
			title: "Reformas de oficinas",
			description: `Renovar el espacio de trabajo tiene efectos tales como la mejora en la motivación y la productividad de los empleados, además de cambiar la imagen corporativa.
			Con colaboraciones destacadas en Amazon y Direct Diagnostic, nos sentimos capacitados para llevar a cabo cualquier proyecto que se nos presente.
			`,
		},
	},
	collaborations: {
		id: 4,
		icon: collaborationstIcon,
		en: {
			title: "Collaborations in civil works",
			description:
				"Creating show homes is vastly different from renovating a home. But by working on this type of project, we have managed to mature as a team, acquiring experience in areas such as organizing the work of large teams, managing production with deadlines, and the logistics that it implies.",
		},
		es: {
			title: "Colaboraciones en obra civil",
			description:
				"Crear pisos piloto es muy diferente a la reforma de un hogar. Pero de ello hemos conseguido madurar como equipo, adquiriendo experiencia en áreas como organizar el trabajo de grandes equipos, gestionar la producción con fechas límites o la logística que implica.",
		},
	},
};

const steps: any = [
	{
		id: "01",
		icon: evaluation,
		text: {
			es: "Evaluación de los trabajos a realizar",
			en: "Evaluation of the work to be carried ou",
		},
	},

	{
		id: "02",
		icon: fastTime,
		text: {
			es: "Entrega del presupuesto en 10 días",
			en: "Delivery of the budget within 10 days",
		},
	},

	{
		id: "03",
		icon: wrench,
		text: {
			es: "Visita con el técnico",
			en: "Visit with the technician",
		},
	},

	{
		id: "04",
		icon: accounts,
		text: {
			es: "Finalización del presupuesto adaptado",
			en: "Finalization of the budget adapted to the client's needs",
		},
	},
];

const Homepage = ({ data, pageContext, location }: any) => {
	// const {lang} = pageContext.metaInfo;

	const { pageData, lang, defLang } = pageContext;

	const {
		header,
		about_owner,
		about_team,
		before_after,
		our_work,
		testimonials,
		process,
		services,
	} = pageData;

	const aboutUsRef = useRef<HTMLDivElement>(null);
	const meetTheTeam = useRef<HTMLDivElement>(null);
	const servicesRef = useRef<HTMLDivElement>(null);
	const contactUsRef = useRef<HTMLDivElement>(null);

	const refs = {
		contactRef: aboutUsRef,
		meetTheTeam: meetTheTeam,
		serviceRef: servicesRef,
		contactUsRef: contactUsRef,
	};

	return (
		<Layout
			lang={lang}
			refs={refs}
			location={location}
			department={data.department}
			defLang={defLang}
			metaInfo={pageContext.metaInfo}
			otherLang={pageContext.otherLang}
		>
			<div className="page-wrapper">
				<HeroSlider {...header} lang={lang} defLang={defLang} />

				<OurServices
					{...services}
					services={servicess}
					lang={lang}
					ref={servicesRef}
					defLang={defLang}
				/>

				<Steps {...process} steps={steps} lang={lang} />

				<OurWork {...our_work} lang={lang} />

				<TextAndImage
					image={ramirez}
					html={about_owner.description[lang]}
					signatureImg={signatureImg}
					ref={aboutUsRef}
					id="about-us"
					lang={lang}
					className="textAndImage-special"
					defLang={defLang}
					reverse = "true"
				>
					<h2>{about_owner.title[lang]}</h2>
				</TextAndImage>

				<TextAndImage
					imagePosition="right"
					image={image2}
					bgImage={imgAndTextBg}
					className="textAndImagespaceing"
					html={about_team.description[lang]}
					ref={meetTheTeam}
					id="meet-the-team"
					lang={lang}
					defLang={defLang}
					reverse = "true"
				>
					<h2>{about_team.title[lang]}</h2>
				</TextAndImage>

				{/* <BeforeAndAfter {...before_after} lang={lang} /> */}

				<TestimonialsList
					{...testimonials}
					allTestimonials={data.allTestimonial.nodes}
					lang={lang}
					defLang={defLang}
				/>
			</div>
		</Layout>
	);
};

export const query = graphql`
	{
		department(name: { eq: "Main" }) {
			name
		}

		template {
			name
			sections {
				keyPrefix
				items {
					... on DataItemTextGet {
						__typename
						key
						locales {
							iso
							value
						}
					}
				}
			}
		}

		allTestimonial {
			nodes {
				id
				locales {
					content
					description
					iso
					title
					value
				}
			}
		}
	}
`;
export default Homepage;

// extracted by mini-css-extract-plugin
export var animateIn = "ourservices-module--animateIn--34R1g";
export var bgImage = "ourservices-module--bgImage--3fmuj";
export var buttonLast = "ourservices-module--buttonLast--lEeHT";
export var container = "ourservices-module--container--34vyT";
export var first = "ourservices-module--first--33FoA";
export var heading = "ourservices-module--heading--2J_2I";
export var icon = "ourservices-module--icon--2rQgR";
export var iconLeft = "ourservices-module--iconLeft--3KaU5";
export var iconRight = "ourservices-module--iconRight--2oISC";
export var inner = "ourservices-module--inner--3e-WC";
export var innerLast = "ourservices-module--innerLast--hH6rE";
export var last = "ourservices-module--last--3bbcn";
export var left = "ourservices-module--left--269s-";
export var right = "ourservices-module--right--2BcCG";
export var service = "ourservices-module--service--1Vni-";
export var services = "ourservices-module--services--25FpH";
export var servicesSection = "ourservices-module--servicesSection--3NtHr";
export var startIcon = "ourservices-module--startIcon--2JLRp";
export var textHolder = "ourservices-module--textHolder--1VzV9";
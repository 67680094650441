// extracted by mini-css-extract-plugin
export var btnFix = "testimonial-module--btnFix--1KOVF";
export var btnHolder = "testimonial-module--btnHolder--33rmb";
export var ctaBtn = "testimonial-module--ctaBtn--3r0oF";
export var ctaTestimonios = "testimonial-module--ctaTestimonios--LsUpu";
export var inputField = "testimonial-module--inputField--IQKDM";
export var lastCta = "testimonial-module--lastCta--Y4RMq";
export var name = "testimonial-module--name--1De5m";
export var section = "testimonial-module--section--1gEJP";
export var singleTestimonial = "testimonial-module--singleTestimonial--c_o96";
export var titleArea = "testimonial-module--titleArea--2VqDO";